import * as React from 'react';
import { SEO } from 'components/SEO';
import { AboutMe } from 'components/AboutMe';
import { ProjectsDesign, ProjectsIT } from 'components/ProjectsPage';

const HomePage: React.FC = () => {
  return (
    <>
      <SEO />
      <AboutMe />
      <ProjectsIT />
      <ProjectsDesign />
    </>
  )
}

export default HomePage;